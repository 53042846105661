import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import axiosInstance from '../../services/AxiosInstance';
import swal from "sweetalert";
import { format } from "date-fns";

//import QRCode from "react-qr-code";

import { Table, Pagination, Badge,Dropdown,Spinner } from "react-bootstrap";

import ColorPicker from "material-ui-color-picker";

const OrderDetails = ({ match, history }) => {

   const dispatch = useDispatch();
   const [order, setOrder] = useState(null);
   const [cards, setCards] = useState([]);
   const [color, setColor] = useState("");



   useEffect(() => {
   
   loadOrderDetails(match.params.id);
  
   }, [match]);

   useEffect(() => {
   
      if(order){
         loadCardsDetails(order.id);
      }
      
   }, [order]);


   const qrCode = (cardId,size,color,bgcolor) => {
      var link = "/api/app/genrate-qr?url="+encodeURI("https://profile.tap1ce.uk/"+cardId)+"&size="+(size??150)+"&color="+(color??"#17556d").replace("#","%23")+"&bg_color="+(bgcolor??"#fff").replace("#","%23");
      console.log("qr link - "+link);
      return link;
   }

    const loadOrderDetails = async (orderId) => {
      const { data } = await axiosInstance.get(
         "admin/orders/"+orderId
       );
 
       if(data.status){
         setOrder(data.order);
       }
    }

    const loadCardsDetails = async (orderId) => {
      const { data } = await axiosInstance.post(
         "admin/cards",
         {order_id:orderId}
       );
 
       if(data.status){
         setCards(data.cards);
       }
    }

    const cardQRLink = (orderItemId,size,color,bgcolor) => {
      const cardIndex = cards.findIndex(
			(x) => x.orderItemId === orderItemId
		  );
		  if (cardIndex != -1) {
			//const link = "https://profile.tap1ce.uk/"+cards[cardIndex]._id;
         //return link;
         return qrCode(cards[cardIndex]._id,size,color,bgcolor);
		  }else{
			return "";
		  }
    }

    const cardQRLink2 = (orderItemId) => {
      const cardIndex = cards.findIndex(
			(x) => x.orderItemId === orderItemId
		  );
		  if (cardIndex != -1) {
			const link = "https://profile.tap1ce.uk/"+cards[cardIndex]._id;
         return link;
         //return qrCode(cards[cardIndex]._id,size,color,bgcolor);
		  }else{
			return "";
		  }
    }

    const getCardID = (orderItemId) => {
      const cardIndex = cards.findIndex(
			(x) => x.orderItemId === orderItemId
		  );
		  if (cardIndex != -1) {
			return cards[cardIndex]._id;
		  }else{
			return "";
		  }
    }


    const productCoverImage = (item,isFront) => {

      var productFrontImage = "";
      var productBackImage = "";

      const variationIndex = item.productId.variations.findIndex(
         (x) => x._id === item.variationId
      );

      if(variationIndex >= 0){
         productFrontImage = item.productId.variations[variationIndex].dimensions.template.frontImage;
         productBackImage = item.productId.variations[variationIndex].dimensions.template.backImage;
      }

      return isFront?productFrontImage:productBackImage;
    }

    const downloadInvoice = async () => {
      const { data } = await axiosInstance.post(
         "admin/order-invoice",
         {
            order_id:match.params.id
         }
       );
 
       if(data.status){
         
         const link = document.createElement("a");
         link.href = data.data;
         document.body.appendChild(link);
         link.click();
         // Clean up and remove the link
         link.parentNode.removeChild(link);

       }
    }

    const subTotal = (items) => {

      var total = 0;
      items.map((item,i)=>{
         total = total + (item.quantity * item.total);
      });

      return total;

    }

    const onQRCownload = (id) => {
      const svg = document.getElementById(id);
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = id;
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;

      /* const cardIndex = cards.findIndex(
			(x) => x.orderItemId === id
		  );
		  if (cardIndex != -1) {
			//const link = "https://profile.tap1ce.uk/"+cards[cardIndex]._id;
         //return link;
         var link =  qrCode(cards[cardIndex]._id,size,color);

         const downloadLink = document.createElement("a");
         downloadLink.download = id;
         downloadLink.href = `${link}`;
         downloadLink.click();

		  }else{
			//return "";
		  } */

    };


   return (
      <Fragment>
         
         <div className="row">
            <div className="col-lg-12">
            {order == null ? <div className="text-center mt-4"><Spinner animation="border" variant="primary" /></div>:
            
            (<div className="card mt-3">
                  <div className="card-header">
                     {" "}
                     #{order.id}  {order.paymentStatus === "paid" ? (
                                          <Badge variant="success light">
                                             Paid
                                          </Badge>
                                       ) : (
                                          <Badge variant="danger light">
                                             Unpaid
                                          </Badge>
                                       )
                                    } <strong>{format(new Date(order.createdAt), "dd/MM/yyyy H:mma")}</strong>{" "}
                     <span className="float-right">
                        <strong>Status:</strong> {order.status}
                     </span>{" "}
                  </div>
                  <div className="card-body">
                     <div className="row">
						<div className="col-xl-3 col-sm-6 mb-4">
							<h6>From:</h6>
							<div><strong>Comket Solutions Pvt Ltd</strong> </div>
                     <div>2, Bhoir Estate , WT Patil Marg ,</div>
                     <div>Near Cng Pump,</div>
                     <div>Opp Arabian Darbar, Chembur</div>
                     <div>Mumbai</div>
                     <div>Maharashtra</div>
                     <div>India - 400071</div>

							{/* <div>Madalinskiego 8</div>
							<div>71-101 Szczecin, Poland</div>
							<div>Email: info@webz.com.pl</div>
							<div>Phone: +48 444 666 3333</div> */}
						</div>
						<div className="col-xl-3 col-sm-6 mb-4">
							<h6>To:</h6>
							<div> <strong>{order.name}</strong><br/><small>{order.email}</small> </div>
                     <div> {order.shipping.address.addressLine1} </div>
                     <div>{order.shipping.address.addressLine2}</div>
							<div>{order.shipping.address.lanmark}</div>
                     <div>{order.shipping.address.city}</div>
							<div>{order.shipping.address.state}</div>
							<div>{order.shipping.address.country} - {order.shipping.address.zip}</div>
						</div>

                  <div className="col-xl-3 col-sm-6 mb-4">
							<h6>Ship Rocket:</h6>
							<div> {order.shipRocket ? (<><strong>Order Id - </strong> {order.shipRocket.orderId??""} </>) : (<></>) } </div>
							<div> {order.shipRocket ? (<><strong>Shipment Id - </strong> {order.shipRocket.shipmentId??""}</>):(<></>)}</div>
                     
						</div>

                  <div className="col-xl-3 col-sm-6 mb-4 text-right">
                  {order.shipRocket?
                        <button className="btn btn-primary" onClick={(e)=>downloadInvoice()}>Download</button>:<></>}
                  </div>

						{/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-4 d-flex justify-content-lg-end justify-content-md-center justify-content-xs-start">
							<div className="row align-items-center">
								
                     </div>
						</div> */}
					</div>
					 <div className="table-responsive">
                        <table className="table table-striped">
                           <thead>
                              <tr>
                                 <th className="center">#</th>
                                 <th>Design</th>
                                 <th>QR</th>
                                 <th>Plan</th>
                                 <th className="right">Amount</th>
                                 <th className="center">Qty</th>
                                 <th className="right">Total</th>
                              </tr>
                           </thead>
                           <tbody>

                              {
                                 order.items.map((item,i) => (
                                    <tr>
                                    <td className="center">{i+1}</td>

                                    <td className="center">

                                       <img className="img-fluid mt-2 mr-2" style={{maxWidth:"150px"}} src={productCoverImage(item,true)} /><br/>

                                       <img className="img-fluid mt-2" style={{maxWidth:"150px"}} src={productCoverImage(item,false)} />
                                       
                                       </td>

                                    <td>
                                    {cardQRLink(item._id) != "" ? (<div className="text-center "> {/* <QRCode id={"QR-"+i} value={cardQRLink(item._id)} /> */}<br/>
                                    
                                    <a className="" href={qrCode(getCardID(item._id),512,color == "" ?"#000000":color,"#fff")} target="_blank" download={qrCode(getCardID(item._id),512,color == "" ?"#000000":color,"#fff")}>
                                       <img src={qrCode(getCardID(item._id),150,color == "" ?"#000000":color,"#fff")} />
                                       {/* <QRCode className="" level="H" id={"QR-Black-"+i} size="150" bgColor="transparent" fgColor={color == "" ?"#000000":color} value={cardQRLink2(item._id)} /> */}</a><br/>
                                    <ColorPicker
                                    className="mt-2"
                                       style={{border:"1px solid "+color}}
                                       name="color"
                                       //defaultValue={color}
                                       value={color}
                                       //internalValue={color}
                                       hintText={color == "" ?"#000000":color}
                                       onChange={(color) => setColor(color)}
                                    />
                                  {/*   <p>{cardQRLink(item._id)}</p> */}
                                    
                                    </div>):(<p>No Card Found</p>)}
                                    

                                    </td>
                                    <td className="left strong">
                                       
                                       {item.productId.name}</td>
                                   
                                    <td className="right">{order.currency} {item.price}</td>
                                    <td className="center">{item.quantity}</td>
                                    <td className="right">{order.currency} {item.total}</td>
                                 </tr>
                                 ))
                              }

                             
                           </tbody>
                        </table>
                     </div>
                     <div className="row">
                        <div className="col-lg-4 col-sm-5"> </div>
                        <div className="col-lg-4 col-sm-5 ml-auto">
                           <table className="table table-clear">
                              <tbody>
                                 <tr>
                                    <td className="left">
                                       <strong>Subtotal</strong>
                                    </td>
                                    <td className="right">{order.currency} {subTotal(order.items)}</td>
                                 </tr>
                                 <tr>
                                    <td className="left">
                                       <strong>Discount (  {order.discountTotal != 0 ?((order.discountTotal * 100)/order.discountTotal):0}%) - {order.coupon}</strong>
                                    </td>
                                    <td className="right">{order.currency} {order.discountTotal}</td>
                                 </tr>
                                
                                 {/* <tr>
                                    <td className="left">
                                       <strong>VAT (10%)</strong>
                                    </td>
                                    <td className="right">$679,76</td>
                                 </tr> */}
                                 <tr>
                                    <td className="left">
                                       <strong>Total</strong>
                                    </td>
                                    <td className="right">
                                       <strong>{order.currency} {order.orderTotal}</strong>
                                       <br />
                                       {/* <strong>0.15050000 BTC</strong> */}
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                     </div>
                  </div>
               </div>
           )}
         </div>
         </div>
      </Fragment>
   );
};

export default OrderDetails;
