import React, { useState,useEffect} from 'react';
import { Link} from 'react-router-dom';
import swal from "sweetalert";
import {nanoid} from 'nanoid';
import xlsx from 'xlsx';
import Moment from 'moment';
import { format } from "date-fns";

import { useDispatch,useSelector } from "react-redux";

import {
	Row,
	Col,
	Card,
	Table,
	Badge,
	Dropdown,
	Modal,
	ProgressBar,
   Button
 } from "react-bootstrap";

 /// imge
import avatar1 from "../../../images/avatar/1.jpg";
import avatar2 from "../../../images/avatar/2.jpg";
import avatar3 from "../../../images/avatar/3.jpg";

import {
    inviteMemberAction,
    bulkInviteMemberAction,
    cancelInvitationAction,
	getInvitationsAction,
   resetAllAction
} from '../../../store/actions/MemberActions';

import {
   profileAction,
} from '../../../store/actions/AuthActions';

import axiosInstance from '../../../services/AxiosInstance';

const MemberPage = ({ props, history }) => {
	
	const dispatch = useDispatch();

    const [postModal, setPostModal] = useState(false);
    const [editProfileModal, setEditProfileModal] = useState(false);
    const [bulkInviteModal, setBulkInviteModal] = useState(false);

    const [inviteList, setInviteList] = useState([]);

    const [members, setMembers] = useState([]);
    const [checkAllMember, setCheckAllMember] = useState(false);

	const invitations = useSelector((state) => state.members.invitations);

   const newInvite = useSelector((state) => state.members.newInvite);
   const bulkInvite = useSelector((state) => state.members.bulkInvite);
   const cancelInvite = useSelector((state) => state.members.cancelInvite);


   //edit profile
   const [activeMember, setActiveMember] = useState("");
  
	//const { invitations} = getInvitations;

	useEffect(() => {
		dispatch(getInvitationsAction({}));
	  }, [dispatch]);


     useEffect(() => {
      if(newInvite){
         setPostModal(false);
         console.log("newInvite - "+JSON.stringify(newInvite));
         if(newInvite.status){
            console.log("newInvite status true");
            swal('Invite Member', newInvite.message, "success");
         }else{
            console.log("newInvite status false - ");
            swal('Oops', newInvite.message,"error");
         }
         
         dispatch(getInvitationsAction());
         loadProfile();

         //dispatch(resetAllAction({}));
      }		
	  }, [newInvite]);


    


     useEffect(() => {
      if(bulkInvite){
         setPostModal(false);
         if(bulkInvite.status){
            setBulkInviteModal(false);
            swal('Bulk Invite Member', bulkInvite.message, "success");
         }else{
            swal('Oops', bulkInvite.message,"error");
         }
         dispatch(getInvitationsAction());
         loadProfile();
         //dispatch(resetAllAction({}));
      }		
	  }, [bulkInvite]);

     useEffect(() => {
      if(cancelInvite){
         if(cancelInvite.status){
            swal('Cancel Invite', cancelInvite.message, "success");
            
         }else{
            swal('Oops', cancelInvite.message,"error");
         }
         dispatch(getInvitationsAction());
         loadProfile();
         //dispatch(resetAllAction({}));
      }		
	  }, [cancelInvite]); 



     
	async function loadProfile(){
		
		try {
			
			const { data } = await axiosInstance.get(
        		"auth/profile"
      		);


	  console.log("profile data - "+JSON.stringify(data));

		if(data.status === false){
			
		}else{
			//setUser(data.user);
			dispatch(profileAction(data.user));
		}
		
		}catch (error) {
			

		}
	
	  }



  // Update profile 
    const [updateProfileFormData, setUpdateProfileFormData ] = useState({
      name:"",
      department:'',
      designation:''
  }); 
  
  // update member function
  const handleUpdateProfileFormChange = (event) => {
      event.preventDefault();    
      const fieldName = event.target.getAttribute('name');
      const fieldValue = event.target.value;
      const newFormData = {...updateProfileFormData};
      newFormData[fieldName] = fieldValue;
      setUpdateProfileFormData(newFormData);
  };

       // Update Profile Submit data
       const handleUpdateProfileFormSubmit = async (event)=> {
         event.preventDefault();
         
         var error = false;
         var errorMsg = '';
 
       if(updateProfileFormData.name === ""){
             error = true;
          errorMsg = 'Name is Required';
       }else if(updateProfileFormData.department === ""){
          error = true;
          errorMsg = 'Department is Required';
       }else if(updateProfileFormData.designation === ""){
          error = true;
          errorMsg = 'Designation is Required';
       }
 
         if(!error){
             
          //invite memner api call
          //dispatch(inviteMemberAction(addFormData.name,addFormData.email,addFormData.department,addFormData.designation,history));
          

          try {
			
            const { data } = await axiosInstance.post(
               "corporate/update-profile",
               { member_id:activeMember,name:updateProfileFormData.name,department:updateProfileFormData.department,designation:updateProfileFormData.designation }
            );
   
   
        console.log("corporate/update-profile - "+JSON.stringify(data));
   
        if(data.success === false){
           //toast.error(data.message);
         swal('Update Member Profile', data.message,"error");

        }else{
           //toast.success(data.message);
         swal('Update Member Profile', data.message, "success");

         updateProfileFormData.email = '';
         updateProfileFormData.department = "";     
         updateProfileFormData.designation = "";  
           //window.location.reload();
   
           setTimeout(() => {
              setEditProfileModal(false);
              dispatch(getInvitationsAction());

            }, 500)

         
          }
         
         }catch (error) {
			
         }


                   
             
         }else{
          swal('Oops', errorMsg, "error");
       }
     };

   // On Edit Profile
   const onEditProfile = (member) => {
      const newFormData = {name:member.name,department:member.department,designation:member.designation};
      setUpdateProfileFormData(newFormData);
      setActiveMember(member.id);
      setEditProfileModal(true);
   }

    // Add data 
    const [addFormData, setAddFormData ] = useState({
		  name:"",
        email:'',
    }); 
    
    // Add member function
    const handleAddFormChange = (event) => {
        event.preventDefault();    
        const fieldName = event.target.getAttribute('name');
        const fieldValue = event.target.value;
        const newFormData = {...addFormData};
        newFormData[fieldName] = fieldValue;
        setAddFormData(newFormData);
    };
    
     // Add Submit data
    const handleAddFormSubmit = (event)=> {
        event.preventDefault();
        var error = false;
		var errorMsg = '';

		if(addFormData.name === ""){
            error = true;
			errorMsg = 'Name is Required';
      }else if(addFormData.email === ""){
            error = true;
			errorMsg = 'Email is Required';
      }else if(addFormData.department === ""){
         error = true;
         errorMsg = 'Department is Required';
      }else if(addFormData.designation === ""){
         error = true;
         errorMsg = 'Designation is Required';
      }

        if(!error){
            
			//invite memner api call
			dispatch(inviteMemberAction(addFormData.name,addFormData.email,addFormData.department,addFormData.designation,history));
         
         addFormData.email = '';
         addFormData.name = "";
         addFormData.department = "";     
         addFormData.designation = "";           
            
        }else{
			swal('Oops', errorMsg, "error");
		}
    };
    

    const onCancelInvitation = async (id) => {
      dispatch(cancelInvitationAction(id,history));
    }

    function inviteStatus(status){

      if(status == "sent"){
         return "text-warning";
      }else if(status == "accepted"){
         return "text-success";
      }else{
         return "text-danger";
      }

    }

    const upload = (e) => {
      e.preventDefault();
      document.getElementById("selectFile").click();
    }


    //bulk invite

    // handleChange is executing as usual
// handleClick basically empty the target.value i.e. event.target.value = ""
const handleClick = event => {
   const { target = {} } = event || {};
   target.value = "";
 };

    const readUploadFile = (e) => {
      e.preventDefault();

      console.log("readUploadFile");
      
      if (e.target.files) {
         console.log("readUploadFile file found");
          const reader = new FileReader();
          reader.onload = (e) => {
              
              const data = e.target.result;
              const workbook = xlsx.read(data, { type: "array" });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              const json = xlsx.utils.sheet_to_json(worksheet);
              
              console.log(json);

              //json to array
              var dummy = [];
              for(let i in json){
               dummy.push({...json[i],id:i});
              }
              
               setInviteList(dummy);
               setBulkInviteModal(true);

              
               e.target.value = null;
              

          };
          reader.readAsArrayBuffer(e.target.files[0]);
      }else{
         console.log("readUploadFile file not found");
      }
  }


   
  function onMemberCheck(data,checked){

   console.log("onMemberCheck - "+checked);

   const dummy = [...members];

   if(checked){

     dummy.push(data);
     setMembers(dummy);

     if(dummy.length != inviteList.length){
      setCheckAllMember(false);
     }else{
      setCheckAllMember(true);
     }
     
     console.log("onMemberCheck item "+data+" added");

   }else{
     const memberIndex = dummy.findIndex(
       (x) => x === data
     );

     if (memberIndex != -1) {
       dummy.splice(memberIndex, 1);
       console.log("onMemberCheck item "+memberIndex+" removed");
     }else{
       //dummy.push(memberId);
       console.log("onMemberCheck item "+memberIndex+" not found");
     }

   console.log("onMemberCheck members - "+JSON.stringify(dummy));

   setMembers(dummy);
   setCheckAllMember(false);
 }
 }

 
 function onAllMemberCheck(checked){

   console.log("onAllMemberCheck - "+checked);

   if(checked){

     let dummy = [];
     for (let key in inviteList) {
       dummy.push(Number(key));
     }

     setMembers(dummy);
     console.log("onAllMemberCheck members - "+JSON.stringify(dummy));
   }else{
     setMembers([]);
     console.log("onAllMemberCheck members removed");
   }

   
   setCheckAllMember(checked);
   
 }

 async function onBulkInvite(){

   let dummy = [];
   for (let key in members) {
      const data = inviteList[members[key]];
      const newData = {
         name:data.name??"",
         email:data.email??"",
         department:data.department??"",
         designation:data.designation??""
      }
     dummy.push(newData);
   }

   console.log("onBulkInvite members - "+JSON.stringify(dummy));

   //invite memner api call
   dispatch(bulkInviteMemberAction(dummy,history));      

 }

    
    return(
        <>
                      

			


            
            <Modal className="modal fade"  show={postModal} onHide={setPostModal} >
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">Invite Member</h4>
									<button type="button" className="close" onClick={()=> setPostModal(false)}><span>×</span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">


										<div className="form-group mb-3">
												<label className="text-black font-w500">Name</label>
												<div className="contact-name">
													<input type="text"  className="form-control"  autocomplete="off"
														name="name" required="required"
                                                        onChange={handleAddFormChange}
														placeholder="Name"
													/>
													<span className="validation-text"></span>
												</div>
											</div>

											
											 <div className="form-group mb-3">
												<label className="text-black font-w500">Email</label>
												<div className="contact-name">
													<input type="email"  className="form-control"  autocomplete="off"
														name="email" required="required"
                                                        onChange={handleAddFormChange}
														placeholder="Email Id"
													/>
													<span className="validation-text"></span>
												</div>
											</div>


                                 <div className="form-group mb-3">
												<label className="text-black font-w500">Department</label>
												<div className="contact-name">
													<input type="text"  className="form-control"  autocomplete="off"
														name="department" required="required"
                                                        onChange={handleAddFormChange}
														placeholder="Department Name"
													/>
													<span className="validation-text"></span>
												</div>
											</div>

                                 <div className="form-group mb-3">
												<label className="text-black font-w500">Designation</label>
												<div className="contact-name">
													<input type="text"  className="form-control"  autocomplete="off"
														name="designation" required="required"
                                                        onChange={handleAddFormChange}
														placeholder="Designation"
													/>
													<span className="validation-text"></span>
												</div>
											</div>
                                         
                                          
			
										</div>
									</div>
								</div>
								<div className="modal-footer">
                                     
                                    <button type="button" onClick={()=> setPostModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>   
                                    <button type="submit" className="btn btn-primary" onClick={handleAddFormSubmit}>Invite</button>    
								</div>
							</form>
                            
						</div>
					</div>
				</Modal>



            <Modal className="modal fade"  show={editProfileModal} onHide={setEditProfileModal} >
					<div className="" role="document">
						<div className="">
							<form >
								<div className="modal-header">
									<h4 className="modal-title fs-20">Edit Member Profile</h4>
									<button type="button" className="close" onClick={()=> setEditProfileModal(false)}><span>×</span></button>
								</div>
								<div className="modal-body">
									<i className="flaticon-cancel-12 close"></i>
									<div className="add-contact-box">
										<div className="add-contact-content">


										<div className="form-group mb-3">
												<label className="text-black font-w500">Name</label>
												<div className="contact-name">
													<input type="text" value={updateProfileFormData.name}  className="form-control"  autocomplete="off"
														name="name" required="required"
                                                        onChange={handleUpdateProfileFormChange}
														placeholder="Name"
													/>
													<span className="validation-text"></span>
												</div>
											</div>

                                 <div className="form-group mb-3">
												<label className="text-black font-w500">Department</label>
												<div className="contact-name">
													<input type="text" value={updateProfileFormData.department}  className="form-control"  autocomplete="off"
														name="department" required="required"
                                                        onChange={handleUpdateProfileFormChange}
														placeholder="Department Name"
													/>
													<span className="validation-text"></span>
												</div>
											</div>

                                 <div className="form-group mb-3">
												<label className="text-black font-w500">Designation</label>
												<div className="contact-name">
													<input type="text" value={updateProfileFormData.designation}  className="form-control"  autocomplete="off"
														name="designation" required="required"
                                                        onChange={handleUpdateProfileFormChange}
														placeholder="Designation"
													/>
													<span className="validation-text"></span>
												</div>
											</div>
                                         
                                          
			
										</div>
									</div>
								</div>
								<div className="modal-footer">
                                     
                                    <button type="button" onClick={()=> setPostModal(false)} className="btn btn-danger"> <i className="flaticon-delete-1"></i> Discard</button>   
                                    <button type="submit" className="btn btn-primary" onClick={handleUpdateProfileFormSubmit}>Save</button>    
								</div>
							</form>
                            
						</div>
					</div>
				</Modal>

           


            <Modal className="modal fade"  show={bulkInviteModal} onHide={setBulkInviteModal} size="lg" >


<Modal.Header>
                              <Modal.Title>Select Members</Modal.Title>
                              <Button
                                 variant=""
                                 className="close"
                                 onClick={() => setBulkInviteModal(false)}
                              >
                                 <span>&times;</span>
                              </Button>
                           </Modal.Header>

                           <Modal.Body>
							
            <Table responsive>
                        <thead>
                           <tr>
                             
                              <th>
                              <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                    <input
                                       type="checkbox"
                                       className="custom-control-input"
                                       id="checkAll"
                                       required=""
                                       checked={checkAllMember}
                                       onChange={(e)=>onAllMemberCheck(e.target.checked)}
                                    />
                                    <label
                                       className="custom-control-label"
                                       htmlFor="checkAll"
                                    ></label>
                                 </div>
                              </th>
                              <th>
                                 <strong>NAME</strong>
                              </th>
                              <th>
                                 <strong>Email</strong>
                              </th>

                               <th>
                                 <strong>Department</strong>
                              </th>

                              <th>
                                 <strong>Designation</strong>
                              </th>
                            
                           </tr>
                        </thead>
                        <tbody>

						{
          inviteList.map((invitation,i) => (

                           <tr key={i}>
                             
                              <td>
                              <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                                    <input
                                       type="checkbox"
                                       className="custom-control-input"
                                       id={"checkbox-"+i}
                                       checked={members.includes(i)}
                                       onChange={(e)=>onMemberCheck(i,e.target.checked)}
                                       required=""
                                    />
                                    <label
                                       className="custom-control-label"
                                       htmlFor={"checkbox-"+i}
                                    ></label>
                                 </div>
                              </td>
                              <td>
                                 <div className="d-flex align-items-center">
                                  {""}
                                    <span className="w-space-no">
                                       {invitation.name}
                                    </span>
                                 </div>
                              </td>
                              <td>{invitation.email} </td>

                              <td>{invitation.department} </td>
                              <td>{invitation.designation} </td>
                             
                           </tr>))
						   
						}

                         
                        </tbody>
                     </Table>
                            
                     </Modal.Body>


                     <Modal.Footer>
                              <Button
                                 variant="danger light"
                                 onClick={() => setBulkInviteModal(false)}
                              >
                                 Close
                              </Button>
                              <Button
                                 variant=""
                                 type="button"
                                 className="btn btn-primary"
                                 onClick={(e)=>onBulkInvite()}
                              >
                                 Invite
                              </Button>
                           </Modal.Footer>

				</Modal>



          
			
         

			<Row>

			<Col lg={12}>
               <Card>
                  <Card.Header>
                     
                        
                     <div class="d-flex">
                     <Card.Title className="mr-auto p-2">MEMBERS</Card.Title>
                     </div>
                        
                     
                        <div class="d-flex justify-content-end">

                        
                        

				<Link className="btn btn-light font-w600 mr-2" onClick={()=> setPostModal(true)}>Invite Member</Link>
				 
            
               <Link className="btn btn-primary font-w600" id='plus' onClick={upload}>Bulk Upload</Link>
               <input id='selectFile' accept=".csv, .xls, .xlsx, text/csv, application/csv,
text/comma-separated-values, application/csv, application/excel,
application/vnd.msexcel, text/anytext, application/vnd. ms-excel,
application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" hidden type="file" 

onChange={readUploadFile}
onClick={handleClick}
/>
           
           </div>

                        
                        
                  </Card.Header>
                  <Card.Body>
                     <Table responsive>
                        <thead>
                           <tr>
                             
                              <th>
                                 <strong>SR NO.</strong>
                              </th>
                              <th>
                                 <strong>NAME</strong>
                              </th>
                              <th>
                                 <strong>Email</strong>
                              </th>
                              <th>
                                 <strong>Department</strong>
                              </th>

                              <th>
                                 <strong>Designation</strong>
                              </th>
                              <th>
                                 <strong>Date</strong>
                              </th>
                              <th>
                                 <strong>Status</strong>
                              </th>
                           </tr>
                        </thead>
                        <tbody>

						{
          invitations.map((invitation,i) => (

                           <tr key={i}>
                             
                              <td>
                                 <strong>{i+1}</strong>
                              </td>
                              <td>
                                 <div className="d-flex align-items-center">
                                  {""}
                                    <span className="w-space-no">
                                       {invitation.name}
                                    </span>
                                 </div>
                              </td>
                              <td>{invitation.email} </td>
                              <td>{invitation.department} </td>
                              <td>{invitation.designation} </td>
                              <td>{ format(new Date(invitation.createdAt), "dd/MM/yyyy H:mma")}</td>
                              <td>
                                 
                                 <div className="d-flex align-items-center">
                                    <i className={"fa fa-circle "+inviteStatus(invitation.status)+" mr-1"}></i>{" "}
                                    {invitation.status}
                                 </div>
                                    
                              </td>
                              <td>
                                 <div className="d-flex">

                                 <Link
            href="#"
            className="btn btn-primary shadow btn-xs sharp"
            onClick={(e)=>onEditProfile(invitation)}
         >
            <i className="fa fa-edit"></i>
         </Link>

                       &nbsp;&nbsp;          

                                 {(invitation.status == "sent")
         ? (<Link
            href="#"
            className="btn btn-danger shadow btn-xs sharp"
            onClick={(e)=>onCancelInvitation(invitation.id)}
         >
            <i className="fa fa-trash"></i>
         </Link>):(<></>)}

                                    
                                 </div>
                              </td>
                           </tr>))
						   
						}

                         
                        </tbody>
                     </Table>
                  </Card.Body>
               </Card>
            </Col>

			</Row>

            
        </>
    );     
}

export default MemberPage;